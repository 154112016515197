export const ORDER_TYPE_NAMES = {
  LIMIT: 'LIMIT',
  MARKET: 'MARKET'
}

export const ORDER_TYPES = [
  {
    value: ORDER_TYPE_NAMES.MARKET,
    title: 'ae_market_order'
  },
  {
    value: ORDER_TYPE_NAMES.LIMIT,
    title: 'ae_limit_order'
  }
]

export const CURRENCY_SHORT_CODES = {
  EUR: 'EUR',
  RUB: 'RUB',
  USD: 'USD',
  RMB: 'RMB',
  AED: 'AED',
  'USD.F': 'USD.F',
  RUBCash: 'RUBCash',
  USDCash: 'USDCash',
  AEDCash: 'AEDCash',
  RMBCash: 'RMBCash',
  MessageToken: 'MessageToken',
  bitcoin: 'BTC',
  bitcoincash: 'BCH',
  bitcoingold: 'BTG',
  dash: 'DASH',
  dogecoin: 'DOGE',
  ethereum: 'ETH',
  ethereumclassic: 'ETC',
  genericFiat: 'genericFiat',
  zcash: 'ZEC',
  lion: 'LION',
  tether: 'USDT',
  litecoin: 'LTC',

  RUBFiat: 'RUB',
  USDFiat: 'USD',
  EURFiat: 'EUR',
  getKeyByValue(value) {
    return Object.keys(this).find(key => this[key] === value) || value
  }
}

export const CURRENCY_SHORT_NAMES = Object.values(CURRENCY_SHORT_CODES).filter(i => typeof i === 'string')

export const FIAT_CURRENCY_NAMES = {
  RUB: 'RUB',
  USD: 'USD',
  EUR: 'EUR',
  RMB: 'RMB',
  AED: 'AED',
  RUBCash: 'RUBCash',
  USDCash: 'USDCash',
  AEDCash: 'AEDCash',
  RMBCash: 'RMBCash',
}

export const FIAT_CURRENCIES = Object.values(FIAT_CURRENCY_NAMES)


export const MIN_CURRENCIES_VALUE = {
  BTC: '0.001',
  LTC: '0.1',
  ETH: '0.01',
  USDT: '5',
  DOGE: '100',
  LION: '1000',
}

export const AE_OPERATION_TYPE_NAMES = {
  AUTOEXCHANGE: 'Autoexchange',
  AUTOEXCHANGE_CASH: 'AutoexchangeCash',
  AUTOEXCHANGE_BILL: 'AutoexchangeBill',
}

export const AE_OPERATION_TYPES = Object.values(AE_OPERATION_TYPE_NAMES)
